import React from 'react';
import Layout from './components/Layout/Layout';
import './App.css';

const App = () => {
  return (
    <div>
      <div>
      <Layout></Layout>
    </div>
    </div>
  )
}

export default App
